import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";

import { Location } from "@angular/common";
import { SendRequestService } from "src/app/services/send-request.service";
// import { MatSidenavModule } from "@angular/material/sidenav";
// import { FieldWrapperComponent } from "../field-wrapper/field-wrapper.component";
// import { MatIconModule } from "@angular/material/icon";
// import { MatTooltipModule } from '@angular/material/tooltip';

import { Subscription } from "rxjs";

export class NavigationButton {
  serial: number;
  label: string;
  routing: string;
  icon: string;
  active: boolean;
  ui_control: string;
  visible: boolean;
  admin: boolean;

}

@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.scss"],
  // standalone: true,
  // imports: [CommonModule,    RouterModule, MatSidenavModule, FieldWrapperComponent, MatIconModule, MatTooltipModule],
})
export class DrawerComponent implements OnInit, OnDestroy {
  @Input() showIcons: boolean = true;
  @Output() onToggleSidebar = new EventEmitter();
  showFiller1 = false;
  showFiller2 = false;
  showFiller3 = false;
  showFiller4 = false;
  showFiller5 = false;
  showFiller6 = false;
  showFiller7 = false;
  showFiller8 = false;
  loggedIn: boolean = false;
  currentUser: any;
  apps: Array<any>;
  selectedAppId: string;
  tables: any[] = [];
  selectedTableName: string;
  pingResult: any;
  version: string;
  apiStatusActive: boolean = true;
  poller: any;
  reports: any[] = [];
  loading: boolean = false;
  dashboards: any;
  showNewDashbord: boolean = false;
  uiControls: any = {};
  drawerControls: any = {};
  subscription: Subscription;
  navigationButtons: NavigationButton[] = [];

  sidebarAuth = ['/home', '/login', '/signup', '/reset-password', '/change-password', '/verify-email'
    , '/invoice-page/', '/product-details', '/product-search', '/contact-us', '/quiz-attempts/', '/landing', '/invoice', '/complete-payment', '/oauth2-login-code/'];

  private navigationButtonsData: any[] = [
    {
      ui_control: null,
      routing: "/user-profile",
      label: "User Profile",
      icon: "person",
      active: true,
      visible: true,
      global_admin: false,
      business_account: false

    },
    {
      ui_control: "cases",
      routing: "/cases",
      label: "Support Cases",
      icon: "support",
      active: true,
      visible: true,
      global_admin: false,
      business_account: false


    },

    {
      ui_control: "orders",
      routing: "/orders",
      label: "Orders",
      icon: "work",
      active: true,
      visible: true,
      global_admin: false,
      business_account: false,


    },
    {
      ui_control: "users",
      routing: "/users",
      label: "Users",
      icon: "supervisor_account",
      active: true,
      visible: true,
      global_admin: true,
      business_account: true,
    },
    {
      ui_control: "user_groups",
      routing: "/user-groups",
      label: "User Groups",
      icon: "groups",
      active: false,
      visible: true,
      global_admin: true,
      business_account: true,

    },
    {
      ui_control: "permission_set",
      routing: "/permission-set",
      label: "Permission Set",
      icon: "security ",
      active: false,
      visible: true,
      global_admin: true,
      business_account: true,

    },

    {
      ui_control: "global_user_search",
      routing: "/global-admin/users-search",
      label: "Global User Search",
      icon: "data_loss_prevention ",
      active: false,
      visible: true,
      global_admin: true

    },

    {
      ui_control: "global_account_search",
      routing: "/global-admin/account-search",
      label: "Global Account Search",
      icon: "person_play ",
      active: false,
      visible: true,
      global_admin: true

    },
    {
      ui_control: "admin_user_details",
      routing: "/global-admin/user-details",
      label: "Admin User Details",
      icon: "shield_person ",
      active: false,
      visible: true,
      global_admin: true

    },
    {
      ui_control: "admin_account_details",
      routing: "/global-admin/account-details",
      label: "Admin Account Details",
      icon: "admin_panel_settings",
      active: false,
      visible: true,
      global_admin: true
    }

  ];

  preferences: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public location: Location,
    public apiService: SendRequestService
  ) {
    this.navigationButtons = [];
    let i = 0;
    for (let r of this.navigationButtonsData) {
      let obj = { ...r };
      if (obj['routing']) {
        obj.serial = i;
        obj.active = false;
        obj.visible = false;
        const d = Object.assign(new NavigationButton(), obj);
        this.navigationButtons.push(d);
        ++i;
      }
    }
  }

  ngOnInit() {
    this.loggedIn = this.apiService.isLoggedIn();
    this.poller = setInterval(() => {
      this.loggedIn = this.apiService.isLoggedIn();
    }, 3000);


    // this.subscription = this.apiService.getSelectedAppControls().subscribe((controls) => {

    //   if (!this.apiService.isEmpty(controls)) {
    //     this.drawerControls = controls;
    //     this.setNavigationButtonVisibility();
    //     this.setActiveRouter();
    //     this.loadPreference();

    //   } else {
    //     this.drawerControls = undefined;
    //   }

    // });
    if (this.loggedIn) {

      let path = `/users/sidebar/Auth`;
      this.apiService.getRequest(path).subscribe((controls) => {
        let error = this.apiService.checkError(controls);
        if (error) {
          this.apiService.notify('error', error);
          return;
        }
        if (!this.apiService.isEmpty(controls)) {
          this.drawerControls = controls;
          this.setNavigationButtonVisibility();
          this.setActiveRouter();
          this.loadPreference();

        } else {
          this.drawerControls = undefined;
        }
      })
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveRouter();

      }
    });

  }

  ngOnDestroy(): void {
    if (this.poller) {
      clearInterval(this.poller);
      this.poller = undefined;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  toggleSideBar() {
    this.apiService.drawerOpen = !this.apiService.drawerOpen;
    if (this.loggedIn && this.apiStatusActive) {
      this.onToggleSidebar.emit({});
    }
  }


  // setNavigationButtonVisibility() {

  //   this.apiService.getNavbarResponse(true).subscribe(navbar_data => {
  //     const error = this.apiService.checkError(navbar_data);
  //     if (error) {
  //       console.error("Failed to get navbar data", error);
  //       return;
  //     }

  //     if (navbar_data?.global_admin) {
  //       this.navigationButtons = this.navigationButtons?.map((item: any) => { return { ...item, visible: true } });
  //     } else if (navbar_data?.business_account) {
  //       this.navigationButtons = this.navigationButtons?.filter((item: any) => !item.global_admin)?.map((item: any) => { return { ...item, visible: true } });

  //     } else {
  //       this.navigationButtons = this.navigationButtons?.filter((item: any) => !item.global_admin && !item.business_account)?.map((item: any) => { return { ...item, visible: true } });
  //     }
  //   });
  // }

  setActiveRouter() {

    if (!Array.isArray(this.navigationButtons)) {
      return;
    }

    const buttons = this.navigationButtons;
    buttons.sort((a, b) => a.routing.length > b.routing.length ? -1 : 1);
    for (let r of buttons) {
      r.active = false;
    }

    for (let r of buttons) {
      if (this.router.url.startsWith(r.routing)) {
        r.active = true;
        this.preferences["route"] = r;
        this.savePreference();
        break;
      }
    }

    buttons.sort((a, b) => a.serial < b.serial ? -1 : 1);

  }

  filteredAdminMenu = (admin: boolean) => {

    if (admin) {
      return this.navigationButtons;
    } else {
      return this.navigationButtons?.filter((item: any) => !item.admin)
    }
  }

  loadPreference() {

    this.apiService.getUserPreference('drawer').subscribe((res) => {
      let error = this.apiService.checkError(res);
      if (error) {
        this.apiService.notify("error", error);
        return;
      }

      this.preferences = res;

      // if (this.preferences.route?.routing) {
      //   this.router.navigate([this.preferences.route.routing]);
      // }


    });
  }


  setNavigationButtonVisibility() {
    this.navigationButtons?.forEach((c) => {
      if (this.drawerControls[c.ui_control]) {
        c.visible = true;
      } else if (c?.ui_control === null) {
        c.visible = true;
      } else {
        c.visible = false;
      }
    });
  }


  savePreference() {
    this.apiService.saveUserPreference('drawer', this.preferences);
  }

}
