<!-- <ng-container *ngIf="loggedIn && drawerControls"> -->
<ng-container *ngIf="loggedIn">

  <div class="av-hamburger">
  </div>
  <mat-drawer-container class="av-sidemenu-main-container" autosize>
    <mat-drawer #drawer class="av-sidemenu-container" mode="side" [(opened)]="apiService.drawerOpen">
      <div class="d-flex flex-end close-btn">
        <button mat-icon-button class="updated-icon-button" (click)="
        toggleSideBar(); ">
          <mat-icon class="material-symbols-outlined">close</mat-icon>

        </button>
      </div>


      <ng-container *ngFor="let nav of navigationButtons">
        <div [routerLink]="[nav.routing]" [ngClass]="{'av-sidemenu-items-active': nav.active}"
          (click)="drawer.close(); setActiveRouter()" *ngIf="nav?.visible">
          <div class="av-sidemenu-items">
            <div class="d-flex">
              <mat-icon class="me-2  material-symbols-outlined">
                {{nav.icon}}
              </mat-icon>
              <span>{{nav.label}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-drawer>


  </mat-drawer-container>

  <div class="fixed-container">
    <div>
      <div>
        <button class="mat-icon-button" (click)="toggleSideBar()">
          <mat-icon class=" material-symbols-outlined">menu</mat-icon>
        </button>
      </div>
      <div class="d-flex flex-column gap-1 position-relative scroll-container" style="padding-bottom: 5rem;">
        <ng-container *ngFor="let nav of navigationButtons">
          <div *ngIf="nav?.visible">
            <button class="mat-icon-button" [ngClass]="{'av-icon-button': nav.active}" [routerLink]="[nav.routing]"
              (click)="setActiveRouter()" matTooltipPosition="after" [matTooltip]="nav.label">
              <mat-icon class="material-symbols-outlined">
                {{nav.icon}}
              </mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</ng-container>