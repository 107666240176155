<h1 mat-dialog-title class="move" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Confirmation
</h1>
<div mat-dialog-content>
    <!-- <p>{{ message }}</p> -->
    <p [innerText]="message"></p>

</div>
<div mat-dialog-actions align="end" class="px-3 pb-3">
    <button mat-stroked-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Confirm</button>
</div>