import { BrowserModule } from "@angular/platform-browser";
import { NgModule, OnInit } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { OverlayModule } from "@angular/cdk/overlay";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { MatBadgeModule } from '@angular/material/badge';
import { NavigationComponent } from "./component/layout/navigation/navigation.component";
// import { NotifierModule } 
import { DatePipe, DecimalPipe } from "@angular/common";
import { DrawerComponent } from "./component/drawer/drawer.component";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderInterceptor } from "./loader-service/loader-interceptor.service";
import { LoaderComponent } from "./component/loader/loader.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { JsonInterceptorService } from "./services/json-interceptor.service";
import { SendRequestService } from "./services/send-request.service";





@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    DrawerComponent,
    LoaderComponent

  ],
  imports: [
    MatIconModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSidenavModule,
    MatSlideToggleModule,
    OverlayModule,
    FormsModule,
    MatBadgeModule,
    MatMenuModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatTreeModule,
    MatTableModule,
    MatSortModule,
    MatStepperModule,
    MatRadioModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatChipsModule,
    MatListModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  providers: [
    CookieService,
    DatePipe,
    DecimalPipe,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 6000 } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JsonInterceptorService,
    //   multi: true,
    //   deps: [SendRequestService]
    // },

  ],
  bootstrap: [AppComponent],
})
export class AppModule implements OnInit {
  opened: boolean;

  constructor() { }

  ngOnInit(): void {
    this.opened = false;
  }
}
