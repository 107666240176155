import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";



const routes: Routes = [,
  {
    path: "",
    loadComponent: () =>
      import(
        "./component/default-homepage/default-homepage.component"
      ).then((component) => component.DefaultHomepageComponent),
  },

  {
    path: "accounts/:id",
    loadComponent: () =>
      import("./Account/account-details/account-details.component").then(
        (component) => component.AccountDetailsComponent
      ),
  },
  {
    path: "accounts",
    loadComponent: () =>
      import("./Account/account-details/account-details.component").then(
        (component) => component.AccountDetailsComponent
      ),
  },
  {
    path: "login",
    loadComponent: () =>
      import("./component/user-services/login/login.component").then(
        (component) => component.LoginComponent
      ),
  },
  {
    path: "oauth2-login-code/:provider",
    loadComponent: () =>
      import("./component/user-services/oauth-callback/oauth-callback.component").then(
        (component) => component.OauthCallbackComponent
      ),
  },
  // {
  //   path: "oauth2-login-code",
  //   loadComponent: () =>
  //     import("./component/user-services/oauth-callback/oauth-callback.component").then(
  //       (component) => component.OauthCallbackComponent
  //     ),
  // },
  {
    path: "logout",
    loadComponent: () =>
      import("./component/user-services/logout/logout.component").then(
        (component) => component.LogoutComponent
      ),
  },
  {
    path: "grant-token",
    loadComponent: () =>
      import("./component/grant-token/grant-token.component").then(
        (component) => component.GrantTokenComponent
      ),
  },
  {
    path: "signup",
    loadComponent: () =>
      import("./component/user-services/sign-up/sign-up.component").then(
        (component) => component.SignUpComponent
      ),
  },
  //  signup should be in spectifc application it will helps us to know fow which application
  {
    path: "change-password",
    loadComponent: () =>
      import(
        "./component/user-services/change-password-no-auth/change-password-no-auth.component"
      ).then((component) => component.ChangePasswordNoAuthComponent),
  },
  {
    path: "reset-password/:id",
    loadComponent: () =>
      import(
        "./component/user-services/reset-password/reset-password.component"
      ).then((component) => component.ResetPasswordComponent),
  },
  {
    path: "verify-email",
    loadComponent: () =>
      import(
        "./component/user-services/process-email-verification/process-email-verification.component"
      ).then((component) => component.ProcessEmailVerificationComponent),
  },
  {
    path: "users",
    loadComponent: () =>
      import("./component/user-services/users/user-list/user-list.component").then(
        (component) => component.UserListComponent
      ),
  },
  {
    path: "users/:id",
    loadComponent: () =>
      import("./component/user-services/users/users.component").then(
        (component) => component.UsersComponent
      ),
  },
  {
    path: "user-profile",
    loadComponent: () =>
      import("./component/user-services/users/users.component").then(
        (component) => component.UsersComponent
      ),
  },
  {
    path: "user-groups/:id",
    loadComponent: () =>
      import("./component/user-group/user-group.component").then(
        (component) => component.UserGroupComponent
      ),
  },
  {
    path: "user-groups",
    loadComponent: () =>
      import("./component/user-group/user-group.component").then(
        (component) => component.UserGroupComponent
      ),
  },
  {
    path: "permission-set",
    loadComponent: () =>
      import("./component/permission-set/permission-set.component").then(
        (component) => component.PermissionSetComponent
      ),
  },
  {
    path: "permission-set/:id",
    loadComponent: () =>
      import("./component/permission-set/permission-set.component").then(
        (component) => component.PermissionSetComponent
      ),
  },
  {
    path: "permission-set/user/:id",
    loadComponent: () =>
      import(
        "./component/admin/permission-set/permission-sets-by-user/permission-sets-by-user.component"
      ).then((component) => component.PermissionSetsByUserComponent),
  },
  {
    path: "permission-set/assignments/:id",
    loadComponent: () =>
      import(
        "./component/admin/permission-set/permission-set-assignment/permission-set-assignment.component"
      ).then((component) => component.PermissionSetAssignmentComponent),
  },
  {
    path: "cases",
    loadComponent: () =>
      import("./component/support-cases/support-cases.component").then(
        (component) => component.MyCasesComponent
      )
  },
  {
    path: "cases/:id",
    loadComponent: () =>
      import("./component/cases/cases.component").then(
        (component) => component.CasesComponent
      ),
  },

  {
    path: "orders",
    loadComponent: () =>
      import("./component/my-orders/my-orders.component").then(
        (component) => component.MyOrdersComponent
      ),
  },

  // {
  //   path: "global-app-config",
  //   loadComponent: () =>
  //     import("./component/admin/app-config/app-config.component").then(
  //       (component) => component.AppConfigComponent
  //     ),
  // },
  // {
  //   path: "global-app-config/:id",
  //   loadComponent: () =>
  //     import("./component/admin/app-config/app-config.component").then(
  //       (component) => component.AppConfigComponent
  //     ),
  // }, this shoul be in main app
  {
    path: "contact-us",
    loadComponent: () =>
      import("./component/contact-us/contact-us.component").then(
        (component) => component.ContactUsComponent
      ),
  },
  // part of main app
  // {
  //   path: "account-app-config",
  //   loadComponent: () =>
  //     import("./component/admin/account-app-config/account-app-config.component").then(
  //       (component) => component.AccountAppConfigComponent
  //     ),
  // },
  // {
  //   path: "account-app-config/:id",
  //   loadComponent: () =>
  //     import("./component/admin/account-app-config/account-app-config.component").then(
  //       (component) => component.AccountAppConfigComponent
  //     ),
  // }, this should be in main app
  {
    path: "global-admin/users-search",
    loadComponent: () =>
      import("./component/global-users/global-users.component").then(
        (component) => component.GlobalUsersComponent
      )
  },
  {
    path: "global-admin/account-search",
    loadComponent: () =>
      import("./Account/global-accounts/global-accounts.component").then(
        (component) => component.GlobalAccountsComponent
      )
  },
  {
    path: "password-policy",
    loadComponent: () =>
      import("./component/password-policy/password-policy.component").then(
        (component) => component.PasswordPolicyComponent
      )
  },
  // {
  //   path: "payments/:id",
  //   loadComponent: () =>
  //     import("./component/payments/payments.component").then(
  //       (component) => component.PaymentsComponent
  //     )
  // },
  // {
  //   path: "payments",
  //   loadComponent: () =>
  //     import("./component/payments/payments.component").then(
  //       (component) => component.PaymentsComponent
  //     )
  // }, order related iteam learning app(order page ) hide from account details

  // {
  //   path: "select-plans",
  //   loadComponent: () =>
  //     import("./component/select-application/select-application.component").then(
  //       (component) => component.SelectApplicationComponent
  //     )
  // },
  {
    path: "orders/:id",
    loadComponent: () =>
      import("./component/order-details/order-details.component").then(
        (component) => component.OrderDetailsComponent
      )
  },
  // need to change selectapp to orders
  {
    path: "invoice/:id",
    loadComponent: () =>
      import("./component/order-invoice/order-invoice.component").then(
        (component) => component.OrderInvoiceComponent
      )
  },
  {
    path: "complete-payment/:orderId",
    loadComponent: () =>
      import("./component/complete-payment/complete-payment.component").then(
        (component) => component.CompletePaymentComponent
      )
  },
  {
    path: "global-admin/user-details/:id",
    loadComponent: () =>
      import("./component/admin/admin-user-details/admin-user-details.component").then(
        (component) => component.AdminUserDetailsComponent
      )
  },
  {
    path: "global-admin/account-details/:id",
    loadComponent: () =>
      import("./component/admin/admin-account-details/admin-account-details.component").then(
        (component) => component.AdminAccountDetailsComponent
      )
  },

  {
    path: "email-unsubscribe",
    loadComponent: () =>
      import("./component/unsubscribe/unsubscribe.component").then(
        (component) => component.UnsubscribeComponent
      )
  },
  // we need to think for workflow rule engine

  {
    path: "**",
    loadComponent: () =>
      import("./component/bad-request/bad-request.component").then(
        (component) => component.BadRequestComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
